<template>
  <div class="data-sources-container">
    <!-- Row for Ladder Logic Tags and VR Tags Selection -->
    <div class="row">
      <h4>Link a Ladder Logic Diagram with a Training Environment</h4>
    </div>
    <div class="row">
      <!-- Ladder Logic Tags Section -->
      <div class="section">
        <div class="input-group">
          <label class="label-text">Select a Ladder Logic Diagram</label>
          <select class="input-field" v-model="selectedLadderLogicDiagram">
            <option v-for="diagram in ladderLogicDiagrams" :key="diagram.id" :value="diagram.id">
              {{ diagram.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- Training Environment Section -->
      <div class="section">
        <div class="input-group">
          <label class="label-text">Select a Digital Twin</label>
          <select class="input-field" v-model="selectedVRScenario">
            <option v-for="scenario in vrScenarioOptions" :key="scenario.id" :value="scenario.id">
              {{ scenario.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- VR Tags and Ladder Logic Variables Table -->
    <div class="row">
      <!-- Ladder Logic Variables Table -->
      <div v-if="variablesTable && variablesTable.length > 0" class="section table-section">
        <label class="label-text">Ladder Logic Tags</label>
        <table class="virtual-tags-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Tag Name</th>
              <th>Class</th>
              <th>Type</th>
              <th>Value</th>
              <th>Link ID</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(variable, index) in variablesTable" :key="variable.id">
              <td>{{ variable.id }}</td>
              <td>{{ variable.name }}</td>
              <td>{{ variable.class }}</td>
              <td>{{ variable.type }}</td>
              <td>{{ variable.value ? '1' : '0' }}</td>
              <td>{{ variable.linkId }}</td>
              <td>
                <input
                  type="checkbox"
                  :checked="selectedLadderLogicTag === index"
                  @change="selectLadderLogicTag(index)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- VR Tags Table -->
      <div v-if="vrTagsTable && vrTagsTable.length > 0" class="section table-section">
        <label class="label-text">Digital Twin Tags</label>
        <table class="virtual-tags-table">
          <thead>
            <tr>
              <th>Tag Name</th>
              <th>Type</th>
              <th>Data Type</th>
              <th>Value</th>
              <th>Link ID</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tag, index) in vrTagsTable" :key="tag.id">
              <td>{{ tag.name }}</td>
              <td>{{ tag.type }}</td>
              <td>{{ tag.dataType }}</td>
              <td>{{ tag.value ? '1' : '0' }}</td>
              <td>{{ tag.linkId !== undefined ? tag.linkId : '' }}</td>
              <td>
                <input
                  type="checkbox"
                  :checked="selectedVrTag === index"
                  @change="selectVrTag(index)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- AV Sections -->
    <div v-if="showAVSections">
      <div class="row">
      <h4>Link a Ladder Logic Diagram with a CyberPhysical Source</h4>
    </div>
      <!-- Ladder Logic Variables Table for AV -->
      <div class="row">
        <!-- Second Ladder Logic Variables Table -->
        <div v-if="variablesTableAV && variablesTableAV.length > 0" class="section table-section">
          <label class="label-text">Ladder Logic Tags</label>
          <table class="virtual-tags-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Tag Name</th>
                <th>Class</th>
                <th>Type</th>
                <th>Value</th>
                <th>Link ID</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="variable in variablesTableAV" :key="variable.id">
                <td>{{ variable.id }}</td>
                <td>{{ variable.name }}</td>
                <td>{{ variable.class }}</td>
                <td>{{ variable.type }}</td>
                <td>{{ variable.value ? '1' : '0' }}</td>
                <td>{{ variable.linkIdAV }}</td>
                <td>
                  <input
                    type="checkbox"
                    :checked="selectedLadderLogicTagAV === variable.id"
                    @change="selectLadderLogicTagAV(variable.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- IIoT Data Sources Table -->
        <div v-if="iiotData.length > 0" class="section table-section">
          <label class="label-text">Cyberphysical Tags</label>
          <table class="virtual-tags-table">
            <thead>
              <tr>
                <th>Tag Name</th>
                <th>LC2030 Tag</th> 
                <th>Value</th>
                <th>Type</th>
                <th>Link ID</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in iiotData" :key="entry.tagName">
                <td>{{ entry.tagName }}</td>
                <td>{{ entry.lc2030Tag }}</td>
                <td>{{ entry.value }}</td>
                <td>{{ entry.type }}</td>
                <td>{{ entry.linkId !== undefined ? entry.linkId : 'No Link' }}</td>
                <td>
                  <input
                    type="checkbox"
                    :checked="selectedIiotTag === entry.tagName"
                    @change="selectIiotTag(entry.tagName)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Connection Buttons -->
    <div class="connection-button-wrapper">
      <button class="connection-button" @click="startConnection">Start Connection</button>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
import { vrScenarios } from "./vrScenarios.js"; // Adjust path as necessary
import { avScenarios } from "./avScenarios.js"; // Import AV scenarios
import { useStore } from "vuex";
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import {
  getCBLevel1VRTags,
  getCBLevel2VRTags,
  getCBLevel3VRTags,
  getCBLevel4VRTags,
  getCBLevel5VRTags,
  getCBLevel6VRTags,
  getCBLevel7VRTags,
  getCBLevel8VRTags,
  getSBGLC2030Level1VRTags,
  getSBGLC2030Level2VRTags,
  getSBGLC2030Level3VRTags,
  getSBGLC2030Level4VRTags,
  getIMEPICALDERASLevel1VRTags,
} from './vrTagFunctions.js';
import { AVDataSources } from './AVSources.js'; // Adjust path as needed

export default {
  name: "IIoTDataSources",
  setup() {
    const store = useStore();

    const ladderLogicDiagrams = ref([]);
    const vrScenarioOptions = ref([]);
    const allScenarios = [...vrScenarios, ...avScenarios];

    const selectedLadderLogicDiagram = computed({
      get: () => store.state.iiotDataSources.selectedDiagramId,
      set: (value) => store.commit("setSelectedDiagramId", value),
    });
    const selectedVRScenario = computed({
      get: () => store.state.iiotDataSources.selectedVRScenarioId,
      set: (value) => store.commit("setSelectedVRScenarioId", value),
    });
    const variablesTable = computed(() => store.state.iiotDataSources.variablesTable || []);
    const variablesTableAV = computed(() => store.state.iiotDataSources.variablesTableAV || []);
    const vrTagsTable = computed(() => store.state.iiotDataSources.vrTagsTable || []);

    const selectedLadderLogicTag = ref(null);
    const selectedVrTag = ref(null);
    const selectedLadderLogicTagAV = ref(null);
    const selectedIiotTag = ref(null);
    
    const ioCardPath = ref("");
    const iiotData = ref([]);
    const selectedDataSource = ref(null);

    const showAVSections = ref(false);

    // Update `selectedScenario` to dynamically reflect the alias
    const selectedScenario = computed(() => vrScenarioOptions.value.find(scenario => scenario.id === selectedVRScenario.value));
    const isAliasSet = computed(() => !!(selectedScenario.value && selectedScenario.value.alias));
    let updateIntervalId;

    // Watcher to update showAVSections and ioCardPath based on scenario selection
    watch(selectedVRScenario, (newVal) => {
      const scenario = vrScenarioOptions.value.find(scenario => scenario.id === newVal);
      if (scenario && scenario.alias) {
        ioCardPath.value = scenario.alias;
        showAVSections.value = true;
        startRealTimeUpdates(); // Automatically start fetching IIoT data when alias is set
      } else {
        showAVSections.value = false;
      }
    });
    const startRealTimeUpdates = () => {
      fetchIIoTData();
      updateIntervalId = setInterval(fetchIIoTData, 1000);
    };

    const handleVrTagUpdate = (tagName, value) => {
      const payload = { tagName, value: value ? 1 : 0 };
      store.commit('updateVrTagValue', payload);
    };

    const fetchLadderLogicDiagrams = () => {
      const token = localStorage.getItem("jwt");
      axios.get("/yaga-auth/ladder-logic", {
        headers: { Authorization: `Bearer ${token}` },
      }).then((response) => {
        ladderLogicDiagrams.value = response.data;
        if (store.state.iiotDataSources.selectedDiagramId) {
          selectedLadderLogicDiagram.value = store.state.iiotDataSources.selectedDiagramId;
        }
      }).catch((error) => {
        console.error("Error fetching ladder logic diagrams:", error);
      });
    };

    const loadVRScenarios = () => {
      vrScenarioOptions.value = allScenarios.map((scenario) => ({
        id: scenario.id,
        name: scenario.name,
        component: scenario.component,
        alias: scenario.alias || null,
      }));
      if (store.state.iiotDataSources.selectedVRScenarioId) {
        selectedVRScenario.value = store.state.iiotDataSources.selectedVRScenarioId;
      }
    };

    const loadLadderLogicDiagram = async (diagramId) => {
      try {
        const token = localStorage.getItem("jwt");
        const response = await axios.get(`/yaga-auth/ladder-logic/${diagramId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          const { variables } = response.data;
          const parsedVariables = variables ? JSON.parse(variables) : [];
          const existingVariables = store.state.iiotDataSources.variablesTable || [];
          const mergedVariables = parsedVariables.map((newVar) => {
            const existingVar = existingVariables.find((v) => v.id === newVar.id);
            return existingVar ? { ...newVar, linkId: existingVar.linkId, linkIdAV: existingVar.linkIdAV } : newVar;
          });
          store.commit("setVariablesTable", mergedVariables);
          store.commit("setVariablesTableAV", JSON.parse(JSON.stringify(mergedVariables)));
        }
      } catch (error) {
        console.error("Error fetching diagram details:", error);
      }
    };

    const loadVRTags = (scenarioId) => {
      let vrTags = [];
      switch (scenarioId) {
        case 'cb-level-1': vrTags = getCBLevel1VRTags(); break;
        case 'cb-level-2': vrTags = getCBLevel2VRTags(); break;
        case 'cb-level-3': vrTags = getCBLevel3VRTags(); break;
        case 'cb-level-4': vrTags = getCBLevel4VRTags(); break;
        case 'cb-level-5': vrTags = getCBLevel5VRTags(); break;
        case 'cb-level-6': vrTags = getCBLevel6VRTags(); break;
        case 'cb-level-7': vrTags = getCBLevel7VRTags(); break;
        case 'cb-level-8': vrTags = getCBLevel8VRTags(); break;
        case 'sbg-level-1': vrTags = getSBGLC2030Level1VRTags(); break;
        case 'sbg-level-2': vrTags = getSBGLC2030Level2VRTags(); break;
        case 'sbg-level-3': vrTags = getSBGLC2030Level3VRTags(); break;
        case 'sbg-level-4': vrTags = getSBGLC2030Level4VRTags(); break;
        case 'imepi-calderas-level-1': vrTags = getIMEPICALDERASLevel1VRTags(); break;
        
      }
      if (vrTags.length > 0) {
        const existingVrTags = store.state.iiotDataSources.vrTagsTable || [];
        const mergedVrTags = vrTags.map((newTag) => {
          const existingTag = existingVrTags.find((t) => t.name === newTag.name);
          return existingTag ? { ...newTag, linkId: existingTag.linkId, direction: existingTag.direction } : newTag;
        });
        store.commit("setVRTagsTable", mergedVrTags);
      } else {
        console.warn(`VR Tags could not be loaded for scenario ID ${scenarioId}`);
      }
    };

    const linkTags = () => {
      const ladderLogicTag = { ...variablesTable.value[selectedLadderLogicTag.value] };
      const vrTag = { ...vrTagsTable.value[selectedVrTag.value] };
      vrTag.linkId = ladderLogicTag.id;
      ladderLogicTag.linkId = ladderLogicTag.id;
      vrTag.direction = ['Output', 'Counter', 'Timer', 'AV Digital Out', 'AV Analog Out'].includes(vrTag.type) ? 'output' : 'input';
      const updatedVariablesTable = [...variablesTable.value];
      updatedVariablesTable[selectedLadderLogicTag.value] = ladderLogicTag;
      store.commit('setVariablesTable', updatedVariablesTable);
      const updatedVrTagsTable = [...vrTagsTable.value];
      updatedVrTagsTable[selectedVrTag.value] = vrTag;
      store.commit('setVRTagsTable', updatedVrTagsTable);
      selectedLadderLogicTag.value = null;
      selectedVrTag.value = null;
    };

    const linkTagsAV = () => {
      if (!selectedLadderLogicTagAV.value || !selectedIiotTag.value) return console.error("Both Ladder Logic AV and IIoT Tag selections are required for linking.");
      const ladderLogicTag = variablesTableAV.value.find(v => v.id === selectedLadderLogicTagAV.value);
      const iiotTag = iiotData.value.find(t => t.tagName === selectedIiotTag.value);
      if (!ladderLogicTag || !iiotTag) return console.error("Selected Ladder Logic AV tag or IIoT tag not found.");
      iiotTag.linkId = ladderLogicTag.id;
      ladderLogicTag.linkIdAV = iiotTag.tagName;
      const updatedVariablesTableAV = variablesTableAV.value.map(variable => variable.id === ladderLogicTag.id ? ladderLogicTag : variable);
      store.commit('setVariablesTableAV', updatedVariablesTableAV);
      const updatedLinkedAVTags = store.state.iiotDataSources.linkedAVTags.filter(tag => tag.tagName !== iiotTag.tagName);
      updatedLinkedAVTags.push(iiotTag);
      store.commit('setLinkedAVTags', updatedLinkedAVTags);
      iiotData.value = iiotData.value.map(tag => tag.tagName === iiotTag.tagName ? { ...tag, linkId: iiotTag.linkId } : tag);
      selectedLadderLogicTagAV.value = null;
      selectedIiotTag.value = null;
    };

    const selectLadderLogicTag = (index) => {
      selectedLadderLogicTag.value = selectedLadderLogicTag.value === index ? null : index;
      if (selectedVrTag.value !== null) linkTags();
    };

    const selectVrTag = (index) => {
      selectedVrTag.value = selectedVrTag.value === index ? null : index;
      if (selectedLadderLogicTag.value !== null) linkTags();
    };

    const selectLadderLogicTagAV = (id) => {
      selectedLadderLogicTagAV.value = selectedLadderLogicTagAV.value === id ? null : id;
      if (selectedIiotTag.value !== null) linkTagsAV();
    };

    const selectIiotTag = (tagName) => {
      selectedIiotTag.value = selectedIiotTag.value === tagName ? null : tagName;
      if (selectedLadderLogicTagAV.value !== null) linkTagsAV();
    };

    const startConnection = () => {
      if (!selectedLadderLogicDiagram.value || !selectedVRScenario.value) return alert("Please select both a Ladder Logic Diagram and a VR Scenario.");
      store.dispatch("setLadderLogicToRun", { diagramId: selectedLadderLogicDiagram.value, runMode: true });
      store.dispatch("setDigitalTwinToRun", { vrScenarioId: selectedVRScenario.value });
      store.dispatch("setSelectedMenu", 'avsimulation');
    };

    const initializeDataSource = () => {
      selectedDataSource.value = AVDataSources.find(
        (source) => source.id === ioCardPath.value || (source.aliases && source.aliases.includes(ioCardPath.value))
      );
      if (selectedDataSource.value) store.dispatch("loadDataSourceModule", selectedDataSource.value.id);
      else alert("Data source not found. Please check the AV Source Alias.");
    };

    const fetchIIoTData = async () => {
      if (!selectedDataSource.value) initializeDataSource();
      if (selectedDataSource.value) await selectedDataSource.value.fetchIIoTData(ioCardPath.value, iiotData);
    };

    const sendDigitalOutput = async (tagName, value) => {
      if (selectedDataSource.value) {
        const alias = ioCardPath.value;
        await selectedDataSource.value.sendDigitalOutput(alias, tagName, value);
       // console.log(`IIoTDataSources.vue - Sent Digital Output to Server: ${alias}, ${tagName}, Value: ${value}`);
      } else {
         console.error("No selected data source available for digital output.");
      }
    };

    const sendAnalogOutput = async (tagName, value) => {
      if (selectedDataSource.value) {
        await selectedDataSource.value.sendAnalogOutput(ioCardPath.value, tagName, value);
      }
    };



    watch(selectedLadderLogicDiagram, (newVal) => {
      if (newVal) {
        loadLadderLogicDiagram(newVal);
      } else {
        const variablesFromVuex = store.getters.getIIoTDataSources.variablesTable;
        if (variablesFromVuex.length > 0) variablesTable.value = variablesFromVuex;
        const AVvariablesFromVuex = store.getters.getIIoTDataSources.variablesTableAV;
        if (AVvariablesFromVuex.length > 0) variablesTableAV.value = AVvariablesFromVuex;
      }
    });

    watch(selectedVRScenario, (newVal) => {
      if (newVal) loadVRTags(newVal);
      else {
        const vrTagsFromVuex = store.getters.getIIoTDataSources.vrTagsTable;
        if (vrTagsFromVuex.length > 0) vrTagsTable.value = vrTagsFromVuex;
      }
    });

    watch(() => [...vrTagsTable.value], (newTags, oldTags) => {
      newTags.forEach((newTag) => {
        const oldTag = oldTags.find((t) => t.id === newTag.id);
        if (oldTag && newTag.value !== oldTag.value && newTag.linkId) {
          const linkedVariable = variablesTable.value.find((variable) => variable.id === newTag.linkId);
          if (linkedVariable && newTag.direction === 'input') {
            store.dispatch("toggleLadderLogicContact", { elementId: linkedVariable.id, newState: newTag.value });
          }
        }
      });
    }, { deep: true });

    watch(() => [...variablesTable.value], (newVariables, oldVariables) => {
      newVariables.forEach((newVariable) => {
        const oldVariable = oldVariables.find((v) => v.id === newVariable.id);
        if (oldVariable && newVariable.value !== oldVariable.value) {
          const linkedVrTag = vrTagsTable.value.find((tag) => tag.linkId === newVariable.id && tag.direction === 'output');
          if (linkedVrTag) {
            const payload = { tagName: linkedVrTag.name, value: newVariable.value };
            if (linkedVrTag.type === 'Counter' || linkedVrTag.type === 'Timer') {
              payload.preset = newVariable.preset || 0;
              payload.accumulated = newVariable.accumulated || 0;
            }
            store.dispatch("updateVrTagValue", payload);
          }
        }
      });
    }, { deep: true });

    watch(iiotData, (newData) => {
      const iiotDataMap = newData.reduce((map, tag) => {
        if (tag.type === 'Digital Input' || tag.type === 'Analog Input' || tag.type === 'Digital Output') map[tag.tagName] = tag.value;
        return map;
      }, {});
      const currentLinkedTags = store.state.iiotDataSources.linkedAVTags;
      const updatedLinkedAVTags = currentLinkedTags.map(linkedTag => {
        const newValue = iiotDataMap[linkedTag.tagName];
        return newValue !== undefined ? { ...linkedTag, value: newValue } : linkedTag;
      });
      if (JSON.stringify(updatedLinkedAVTags) !== JSON.stringify(currentLinkedTags)) {
        store.commit('setLinkedAVTags', updatedLinkedAVTags);
      }
    }, { deep: true });

    watch(() => store.state.iiotDataSources.variablesTableAV, (newVariablesAV) => {
      const linkedAVTags = store.state.iiotDataSources.linkedAVTags;
      newVariablesAV.forEach((variable) => {
        if (variable.id.startsWith("C")) {
          const booleanValue = variable.value === 1;
          const linkedTag = linkedAVTags.find(tag => tag.linkId === variable.id);
          if (linkedTag && linkedTag.tagName) {
            store.dispatch("sendDigitalOutput", { alias: ioCardPath.value, tagName: linkedTag.tagName, value: booleanValue });
          }
        }
      });
    }, { deep: true });

    onMounted(() => {
      fetchLadderLogicDiagrams();
      loadVRScenarios();
      selectedLadderLogicDiagram.value = store.state.iiotDataSources.selectedDiagramId;
      selectedVRScenario.value = store.state.iiotDataSources.selectedVRScenarioId;
      window.addEventListener("start-button-pressed", () => {
        handleVrTagUpdate("Start Button", true);
        setTimeout(() => handleVrTagUpdate("Start Button", false), 100);
      });
      window.addEventListener("stop-button-pressed", () => {
        handleVrTagUpdate("Stop Button", true);
        setTimeout(() => handleVrTagUpdate("Stop Button", false), 100);
      });
    });

    onUnmounted(() => {
      window.removeEventListener("start-button-pressed", () => handleVrTagUpdate("Start Button", false));
      window.removeEventListener("stop-button-pressed", () => handleVrTagUpdate("Stop Button", false));
      if (updateIntervalId) clearInterval(updateIntervalId);
    });

    return {
      ladderLogicDiagrams,
      vrScenarioOptions,
      selectedLadderLogicDiagram,
      selectedVRScenario,
      variablesTable,
      vrTagsTable,
      selectedLadderLogicTag,
      selectedVrTag,
      selectLadderLogicTag,
      selectVrTag,
      startConnection,
      ioCardPath,
      iiotData,
      fetchIIoTData,
      startRealTimeUpdates,
      selectedLadderLogicTagAV,
      selectedIiotTag,
      selectLadderLogicTagAV,
      selectIiotTag,
      variablesTableAV,
      showAVSections,
      isAliasSet,
      selectedScenario,
      sendDigitalOutput,
      sendAnalogOutput,
    };
  },
};
</script>


  


  <style scoped>
 .data-sources-container {
  padding: 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Add this to allow wrapping of sections */
}

.section {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.table-section {
  margin-top: 20px;
}

h2 {
  color: #03abff;
  margin-bottom: 10px;
}

h4 {
  color: #03abff;
  margin-bottom: 10px;
}

.label-text {
  color: #ffc300;
  font-weight: bold;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-field {
  background-color: #1b263b;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  color: lightgray;
  font-weight: normal;
}

select.input-field {
  background-color: #1b263b;
  color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
}

select.input-field option {
  background-color: #1b263b;
  color: white;
}

select.input-field option:hover {
  background-color: #ffc300;
}

/* Styles for the virtual tags table */
.virtual-tags-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.virtual-tags-table th,
.virtual-tags-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.virtual-tags-table th {
  background-color: #1b263b;
  color: #ffc300;
}

.virtual-tags-table td {
  background-color: #1b263b;
  color: lightgray;
}

.connection-button-wrapper {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  margin-top: 20px; /* Add some space on top */
}

.connection-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: #000814;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.connection-button:hover {
  filter: brightness(0.9);
}

/* Media query for smaller screens */
@media screen and (max-width: 950px) {
  .row {
    flex-direction: column; /* Stack the sections vertically */
  }

  .section {
    width: 100%; /* Full width for each section */
    margin-bottom: 20px; /* Add some space between the sections */
  }
}


  </style>
  
<!-- test of implementations of teleportation points in the boiler system -->
<template>
  <div class="vr-container">
    <!-- Ajout du bouton plein écran -->
    <button class="fullscreen-btn" @click="toggleFullscreen">
      <span v-if="!isFullscreen">⛶</span>
      <span v-else>⛶</span>
    </button>

    <!-- Loading Screen -->
    <div v-if="loading" class="loading-screen">
      <div class="loading-logo-container">
        <!-- Circular progress bar (optional) -->
        <svg class="progress-circle" viewBox="0 0 36 36">
          <path
            class="circle-bg"
            d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            class="circle"
            stroke-dasharray="100, 100"
            :stroke-dashoffset="100 - loadingPercentage"
            d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <!-- Rotated Logo -->
          <g transform="rotate(90 18 18)">
            <image :href="logo" x="8" y="8" height="20" width="20" />
          </g>
        </svg>

        <div class="loading-text">Loading... {{ loadingPercentage }}%</div>
      </div>
    </div>

    <!-- Babylon.js canvas -->
    <canvas ref="renderCanvas" class="canvas-container"></canvas>
  </div>
</template>

<script>
import {
  Engine,
  Scene,
  ArcRotateCamera,
  DirectionalLight,
  HemisphericLight,
  Vector3,
  SceneLoader,
  Color3,
  StandardMaterial,
  ActionManager,
  Animation,
  ExecuteCodeAction,
  Sound,
  PhotoDome,
  Tools,
  DynamicTexture,
  Viewport,
  ParticleSystem,
  Color4,
} from "@babylonjs/core";
import "@babylonjs/core/XR";
import "@babylonjs/loaders/glTF";
import logo from "@/assets/Logo_Medusa_Digital_Dark.webp";
import { ref, watch, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { MeshBuilder } from "@babylonjs/core";

export default {
  name: "IMEPICALDERASLevel1",
  setup() {
    const store = useStore();
    const renderCanvas = ref(null);

    // Loader states
    const loading = ref(true);
    const loadingPercentage = ref(0);

    let engine = null;
    let scene = null;

    // Ajout de l'état plein écran
    const isFullscreen = ref(false);

    // Déplacer la fonction teleportCamera ici, avant son utilisation
    const teleportCamera = (position, scene) => {
      const camera = scene.activeCamera;
      const targetPosition = new Vector3(5.8, -1.2, -3.5);

      const minHeight = 2;
      const heightOffset = Math.max(position.y + 8, minHeight);

      const cameraPosition = new Vector3(position.x, heightOffset, position.z);

      const distance = Vector3.Distance(cameraPosition, targetPosition);
      const idealDistance = Math.min(
        Math.max(distance * 7, camera.lowerRadiusLimit),
        camera.upperRadiusLimit
      );

      // Angle beta plus bas (environ 30 degrés)
      const idealBeta = Math.PI / 6; // Changé de Math.PI / 3 à Math.PI / 6

      Animation.CreateAndStartAnimation(
        "cameraTransition",
        camera,
        "position",
        30,
        30,
        camera.position,
        cameraPosition,
        Animation.ANIMATIONLOOPMODE_CONSTANT
      );

      Animation.CreateAndStartAnimation(
        "targetTransition",
        camera,
        "target",
        30,
        30,
        camera.target,
        targetPosition,
        Animation.ANIMATIONLOOPMODE_CONSTANT
      );

      camera.radius = idealDistance * 2;
      camera.beta = Math.max(
        camera.lowerBetaLimit,
        Math.min(idealBeta, camera.upperBetaLimit)
      );
    };

    // --------------------------
    // Lamp & Material Mappings
    // --------------------------
    const lampMappings = [
      {
        lampNodeName: "Lamp_Color_1",
        vrTagName: "LampM1",
        materialName: "Lamp_Color_Choose_1",
        material: null,
      },
      {
        lampNodeName: "Lamp_Color_2",
        vrTagName: "LampM2",
        materialName: "Lamp_Color_Choose_2",
        material: null,
      },
      {
        lampNodeName: "Lamp_Color_3",
        vrTagName: "LampM3",
        materialName: "Lamp_Color_Choose_3",
        material: null,
      },
      {
        lampNodeName: "Lamp_Color_4",
        vrTagName: "LampM4",
        materialName: "Lamp_Color_Choose_4",
        material: null,
      },
      {
        lampNodeName: "Lamp_Color_5",
        vrTagName: "LampQ1",
        materialName: "Lamp_Color_Choose_5",
        material: null,
      },
      {
        lampNodeName: "Lamp_Color_6",
        vrTagName: "LampSensorB1",
        materialName: "Lamp_Color_Choose_6",
        material: null,
      },
      {
        lampNodeName: "Lamp_Color_7",
        vrTagName: "LampSensorB2",
        materialName: "Lamp_Color_Choose_7",
        material: null,
      },
      {
        lampNodeName: "Lamp_Color_8",
        vrTagName: "LampSensorB3",
        materialName: "Lamp_Color_Choose_8",
        material: null,
      },
    ];

    const materialMappings = [
      {
        vrTagName: "LampQ1",
        materials: [
          {
            meshName: "Text_Heating",
            materialName: "Legend_Heat",
            material: null,
          },
          {
            meshName: "Water_T1_Heat",
            materialName: "Water_Mat_T1_Heat",
            material: null,
          },
        ],
      },
      {
        vrTagName: "LampM3",
        materials: [
          {
            meshName: "Text_Transfer_T1_T2",
            materialName: "Legend_Transfer_T1_T2",
            material: null,
          },
        ],
      },
      {
        vrTagNames: ["LampM1", "LampM2"],
        materials: [
          {
            meshName: "Text_Transfer_T2_T1",
            materialName: "Legend_Transfer_T2_T1",
            material: null,
          },
        ],
      },
      {
        vrTagName: "LampSensorB1",
        materials: [
          {
            meshName: "Water_T1_High",
            materialName: "Water_Mat_T1_High",
            material: null,
          },
        ],
      },
      {
        vrTagName: "LampSensorB2",
        materials: [
          {
            meshName: "Water_T1_Medium",
            materialName: "Water_Mat_T1_Medium",
            material: null,
          },
        ],
      },
      {
        vrTagName: "LampSensorB3",
        materials: [
          {
            meshName: "Water_T1_Low",
            materialName: "Water_Mat_T1_Low",
            material: null,
          },
        ],
      },
    ];

    let buttonClickSound = null;
    let factorySound = null;

    // ---------------------------------------------------------
    // 1) Files to Load (Chunk-based: each file = +1 increment)
    // ---------------------------------------------------------
    // Example load order (smaller to bigger, approximate):
    // 1) SpaceShip_platform.gltf
    // 2) SpaceShip_floor.gltf
    // 3) BoschUL-S1.gltf (with position/scale tweak, but no "postProcess")
    const filesToLoad = [
      { fileName: "SpaceShip_decals.gltf" },
      { fileName: "SpaceShip_platform.gltf" },
      { fileName: "SpaceShip_Vessel.gltf" },
      { fileName: "SpaceShip_floor.gltf" },
      { fileName: "SpaceShip_props.gltf" },
      {
        fileName: "BoschUL-S1.gltf",
        // We'll just store a boolean to do the transform in loadOneFile
        transformBosch: true,
      },
    ];

    // We'll track how many files have finished
    let filesCompleted = 0;
    const totalFiles = filesToLoad.length;

    // ---------------------------------------------------------
    // 2) Helper: Load One File, increment chunk progress
    // ---------------------------------------------------------
    function loadOneFile(asset) {
      return new Promise((resolve, reject) => {
        SceneLoader.ImportMesh(
          "",
          "/assets/",
          asset.fileName,
          scene,
          (meshes) => {
            // If we specifically want to reposition BoschUL-S1, do it here
            if (asset.transformBosch && meshes.length > 0) {
              const boschMesh = meshes[0];
              boschMesh.position.x += 5.8; // Move to the side
              boschMesh.position.y -= 3; // Move down
              boschMesh.position.z -= 3.5; // Move forward
              boschMesh.scaling = new Vector3(-1.3, 1.3, 1.3); // Adjust scale
            }

            // Once the file is fully loaded, increment chunk-based progress
            filesCompleted++;
            loadingPercentage.value = Math.floor(
              (filesCompleted / totalFiles) * 100
            );

            resolve(meshes);
          },
          null, // no partial progress callback
          (scene, error) => {
            reject(error);
          }
        );
      });
    }

    // ---------------------------------------------------------
    // 3) (Removed postLoadLC2030; no post-processing logic)
    // ---------------------------------------------------------

    // ---------------------------------------------------------
    // 4) Initialize Babylon Scene
    // ---------------------------------------------------------
    const initBabylonScene = async () => {
      const canvas = renderCanvas.value;
      engine = new Engine(canvas, true, { preserveDrawingBuffer: true });
      engine.useIdleDetection = false;
      scene = new Scene(engine);
      scene.clearColor = new Color3(0.95, 0.95, 0.95);

      const camera = new ArcRotateCamera(
        "camera",
        0,
        Math.PI / 2.5,
        5,
        new Vector3(10, 1, -3.85),
        scene
      );
      camera.attachControl(canvas, true);

      // Limites plus strictes pour un meilleur contrôle
      camera.lowerBetaLimit = Math.PI / 6; // ~30 degrés minimum
      camera.upperBetaLimit = Math.PI / 2.1; // ~85 degrés maximum
      camera.lowerRadiusLimit = 8; // Distance minimale augmentée
      camera.upperRadiusLimit = 50; // Distance maximale

      const light = new DirectionalLight(
        "dirLight",
        new Vector3(0, -1, 1),
        scene
      );
      light.position = new Vector3(0, 5, -10);
      light.intensity = 0.6;

      const hemiLight = new HemisphericLight(
        "hemiLight",
        new Vector3(0, 1, 0),
        scene
      );
      hemiLight.intensity = 0.3;

      // PhotoDome
      const skyDome = new PhotoDome(
        "spaceDome",
        "/assets/space_3.jpeg",
        { resolution: 256, size: 500 },
        scene
      );
      skyDome.mesh.rotation.y = Tools.ToRadians(45);

      // Environment
      const environment = scene.createDefaultEnvironment({
        enableGroundShadow: true,
        groundYBias: 2.8,
      });
      environment.setMainColor(new Color3(0.95, 0.95, 0.95));
      if (environment.skybox) {
        environment.skybox.material.alpha = 0;
      }
      if (environment.ground) {
        environment.ground.isVisible = false;
      }

      // Sounds
      buttonClickSound = new Sound(
        "buttonClick",
        "/assets/button_click.mp3",
        scene,
        null,
        { autoplay: false }
      );
      factorySound = new Sound(
        "factorySound",
        "/assets/factory_sound.mp3",
        scene,
        null,
        { loop: true, autoplay: false }
      );

      // ------------------------------------------------
      // PARALLEL LOAD ALL FILES (with chunk-based progress)
      // ------------------------------------------------
      const promises = filesToLoad.map((asset) => loadOneFile(asset));
      // Wait for all
      await Promise.all(promises);

      // Hide loader
      loading.value = false;

      // XR
      let isXRSupported = await checkXRSupport();
      if (isXRSupported) {
        const xrHelper = await scene.createDefaultXRExperienceAsync({
          floorMeshes: [environment.ground],
        });
        xrHelper.teleportation.addFloorMesh(environment.ground);
        scene.xrHelper = xrHelper;
      }

      engine.runRenderLoop(() => {
        scene.render();
      });

      // Ajout d'un gestionnaire de redimensionnement amélioré
      const resizeHandler = () => {
        engine.resize();
        // Forcer une mise à jour du viewport de la caméra
        if (scene.activeCamera) {
          scene.activeCamera.viewport = new Viewport(0, 0, 1.0, 1.0);
        }
      };

      // Remplacer l'ancien gestionnaire de redimensionnement
      window.removeEventListener("resize", engine.resize);
      window.addEventListener("resize", resizeHandler);

      // Ajouter un gestionnaire pour les changements de mode plein écran
      document.addEventListener("fullscreenchange", () => {
        setTimeout(resizeHandler, 100); // Petit délai pour laisser le temps à l'écran de s'ajuster
      });

      // Move markers creation here, after scene is initialized
      const markerPositions = [
        new Vector3(12.8, -4.25, 3.5),
        new Vector3(-1.2, -4.25, 3.5),
        new Vector3(12.8, -4.25, -10.5),
        new Vector3(-1.2, -4.25, -10.5),
      ];

      //let activeMarker = null;

      markerPositions.forEach((position, index) => {
        // Marqueur au sol (commenté)
        /*
          const marker = MeshBuilder.CreateCylinder(
            `teleportMarker_${index}`,
            {
              height: 0.05,
              diameter: 1,
              tessellation: 32,
            },
            scene
          );
          marker.position = position;
  
          const markerMaterial = new StandardMaterial(`markerMaterial_${index}`, scene);
          markerMaterial.diffuseColor = new Color3(60/255, 110/255, 125/255);
          markerMaterial.emissiveColor = new Color3(30/255, 55/255, 62/255);
          marker.material = markerMaterial;
          */

        // Configuration du halo invisible mais interactif
        const halo = MeshBuilder.CreateCylinder(
          `teleportHalo_${index}`,
          {
            height: 2,
            diameter: 1.2,
            diameterTop: 1.2,
            diameterBottom: 1.2,
            tessellation: 64,
            enclose: true,
            thickness: 0.05,
            cap: Engine.CAP_ALL,
          },
          scene
        );

        // Position ajustée : position.y + (hauteur/2)
        halo.position = new Vector3(position.x, position.y + 1, position.z);

        // Configuration du matériau pour le halo invisible mais interactif
        const haloMaterial = new StandardMaterial(
          `haloMaterial_${index}`,
          scene
        );
        haloMaterial.alpha = 0; // Complètement transparent
        haloMaterial.alphaMode = Engine.ALPHA_COMBINE;
        haloMaterial.backFaceCulling = false;

        // Appliquer le matériau et les propriétés d'interaction
        halo.material = haloMaterial;
        halo.isPickable = true;
        halo.isVisible = true; // Changé à true mais toujours transparent
        halo.visibility = 0; // Garde le mesh invisible

        // Configuration explicite de la détection des collisions
        halo.checkCollisions = true;
        halo.actionManager = new ActionManager(scene);

        // Ajout de l'action de téléportation
        halo.actionManager.registerAction(
          new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
            teleportCamera(position, scene);
          })
        );

        // Création du cercle lumineux périphérique avec épaisseur augmentée
        const glowRing = MeshBuilder.CreateTorus(
          `teleportRing_${index}`,
          {
            diameter: 1.2, // Diamètre moyen inchangé
            thickness: 0.12, // Augmentation de l'épaisseur (était 0.08)
            tessellation: 64, // Pour un rendu bien lisse
          },
          scene
        );

        glowRing.position = new Vector3(
          position.x,
          position.y + 0.01,
          position.z
        );

        const ringMaterial = new StandardMaterial(
          `ringMaterial_${index}`,
          scene
        );
        ringMaterial.emissiveColor = new Color3(1, 1, 1);
        ringMaterial.diffuseColor = new Color3(1, 1, 1);
        ringMaterial.specularColor = new Color3(0, 0, 0);
        ringMaterial.alpha = 0.9;
        ringMaterial.alphaMode = Engine.ALPHA_ADD;
        ringMaterial.disableLighting = true;

        const ringTexture = new DynamicTexture(
          `ringTexture_${index}`,
          256,
          scene,
          false
        );
        const ringCtx = ringTexture.getContext();

        const radialGradient = ringCtx.createRadialGradient(
          128,
          128,
          100,
          128,
          128,
          128
        );
        radialGradient.addColorStop(0.7, "rgba(255, 255, 255, 1.0)");
        radialGradient.addColorStop(0.8, "rgba(255, 255, 255, 0.8)");
        radialGradient.addColorStop(0.9, "rgba(255, 255, 255, 0.4)");
        radialGradient.addColorStop(1, "rgba(255, 255, 255, 0)");

        ringCtx.fillStyle = radialGradient;
        ringCtx.fillRect(0, 0, 256, 256);
        ringTexture.update();

        ringMaterial.opacityTexture = ringTexture;
        glowRing.material = ringMaterial;

        // Animation de rotation plus rapide
        scene.registerBeforeRender(() => {
          glowRing.rotation.y += 0.01;
        });

        // Création du système de particules pour chaque marqueur
        const particleSystem = new ParticleSystem(
          `haloParticles_${index}`,
          2000,
          scene
        );

        // Texture des particules
        particleSystem.particleTexture = new DynamicTexture(
          `particleTexture_${index}`,
          32,
          scene,
          false
        );
        const particleContext = particleSystem.particleTexture.getContext();
        const particleGradient = particleContext.createRadialGradient(
          16,
          16,
          0,
          16,
          16,
          16
        );
        particleGradient.addColorStop(0, "rgba(180, 220, 255, 1.0)");
        particleGradient.addColorStop(1, "rgba(180, 220, 255, 0)");
        particleContext.fillStyle = particleGradient;
        particleContext.fillRect(0, 0, 32, 32);
        particleSystem.particleTexture.update();

        // Position de l'émetteur
        particleSystem.emitter = new Vector3(
          position.x,
          position.y + 0.01,
          position.z
        );

        // Configuration de l'émission en cercle via startPositionFunction
        particleSystem.startPositionFunction = (
          worldMatrix,
          positionToUpdate
        ) => {
          const angle = Math.random() * Math.PI * 2;
          const radius = 0.6;
          positionToUpdate.x = radius * Math.cos(angle);
          positionToUpdate.z = radius * Math.sin(angle);
          positionToUpdate.y = 0;
          Vector3.TransformCoordinatesFromFloatsToRef(
            positionToUpdate.x,
            positionToUpdate.y,
            positionToUpdate.z,
            worldMatrix,
            positionToUpdate
          );
        };

        // Propriétés des particules
        particleSystem.color1 = new Color4(0.7, 0.85, 1.0, 1.0);
        particleSystem.color2 = new Color4(0.7, 0.85, 1.0, 1.0);
        particleSystem.colorDead = new Color4(0.7, 0.85, 1.0, 0.0);

        particleSystem.minSize = 0.05;
        particleSystem.maxSize = 0.15;
        particleSystem.minLifeTime = 1.5; // Augmenté pour permettre aux particules de monter plus haut
        particleSystem.maxLifeTime = 3; // Augmenté pour permettre aux particules de monter plus haut

        // Émission moins dense (réduite de 50%)
        particleSystem.emitRate = 150; // Réduit de 300 à 150

        // Vitesse augmentée pour atteindre une hauteur 50% plus grande
        particleSystem.minEmitPower = 0.075; // Augmenté de 50%
        particleSystem.maxEmitPower = 0.3; // Augmenté de 50%
        particleSystem.updateSpeed = 0.01;

        // Mouvement vertical avec gravité réduite pour permettre plus de hauteur
        particleSystem.direction1 = new Vector3(0, 1, 0);
        particleSystem.direction2 = new Vector3(0, 1, 0);
        particleSystem.gravity = new Vector3(0, 0.025, 0); // Réduite pour permettre aux particules de monter plus haut

        // Démarrage du système de particules
        particleSystem.start();
      });

      // Configuration de la scène pour la gestion de la transparence
      scene.setRenderingAutoClearDepthStencil(1, false);
      scene.enableDepthWriter = true;
    };

    // ---------------------------------------------------------
    // 5) Setup button interactions
    // ---------------------------------------------------------
    const setupButtonInteraction = (buttonMesh, scene, vrTagName) => {
      const colorMesh =
        buttonMesh
          .getChildMeshes()
          .find((m) => m.name === `${buttonMesh.name}_Color`) || buttonMesh;
      const originalMaterial = colorMesh.material;
      const pressedMaterial = new StandardMaterial(
        `pressedMaterial_${vrTagName}`,
        scene
      );
      pressedMaterial.diffuseColor = new Color3(0.5, 1, 0.5);

      buttonMesh.actionManager = new ActionManager(scene);
      buttonMesh.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
          // Click sound
          if (buttonClickSound) {
            buttonClickSound.play();
          }
          // Animate press
          colorMesh.material = pressedMaterial;
          const downPosition = buttonMesh.position.clone();
          downPosition.y -= 0.05;

          const pressDownAnimation = new Animation(
            "pressDownAnimation",
            "position",
            30,
            Animation.ANIMATIONTYPE_VECTOR3
          );
          pressDownAnimation.setKeys([
            { frame: 0, value: buttonMesh.position.clone() },
            { frame: 5, value: downPosition },
          ]);

          const releaseAnimation = new Animation(
            "releaseAnimation",
            "position",
            30,
            Animation.ANIMATIONTYPE_VECTOR3
          );
          releaseAnimation.setKeys([
            { frame: 0, value: downPosition },
            { frame: 5, value: buttonMesh.position.clone() },
          ]);

          scene.beginDirectAnimation(
            buttonMesh,
            [pressDownAnimation],
            0,
            5,
            false,
            1.0,
            () => {
              scene.beginDirectAnimation(
                buttonMesh,
                [releaseAnimation],
                0,
                5,
                false,
                1.0,
                () => {
                  colorMesh.material = originalMaterial;
                }
              );
            }
          );

          // VR tag input
          handleButtonPress(vrTagName);
        })
      );
    };

    const handleButtonPress = (vrTagName) => {
      const iiotDataSources = store.getters.getIIoTDataSources;
      const linkedVrTag = iiotDataSources.vrTagsTable.find(
        (tag) => tag.name === vrTagName
      );
      if (linkedVrTag && linkedVrTag.direction === "input") {
        store.dispatch("updateVrTagValue", { tagName: vrTagName, value: true });
        setTimeout(() => {
          store.dispatch("updateVrTagValue", {
            tagName: vrTagName,
            value: false,
          });
        }, 100);
      }
    };

    // ---------------------------------------------------------
    // 6) Monitor Lamps & Materials
    // ---------------------------------------------------------
    const monitorLamps = () => {
      watch(
        () => store.getters.getIIoTDataSources.vrTagsTable,
        (vrTags) => {
          lampMappings.forEach((lamp) => {
            const lampTag = vrTags.find(
              (tag) => tag.id === lamp.vrTagName && tag.direction === "output"
            );
            if (lampTag && lamp.material) {
              updateLampMaterial(lamp.material, lampTag.value);
            }
          });
        },
        { deep: true }
      );
    };

    const updateLampMaterial = (material, isActive) => {
      if (material) {
        if (isActive) {
          material.diffuseColor = new Color3(1, 1, 0);
          material.emissiveColor = new Color3(1, 1, 0);
        } else {
          material.diffuseColor = new Color3(1, 1, 1);
          material.emissiveColor = new Color3(0, 0, 0);
        }
      }
    };

    const monitorMaterials = () => {
      materialMappings.forEach((mapping) => {
        if (mapping.vrTagName) {
          monitorMaterial(mapping.vrTagName, mapping.materials);
        } else if (mapping.vrTagNames) {
          monitorMaterialsMultipleTags(mapping.vrTagNames, mapping.materials);
        }
      });
    };

    const monitorMaterial = (vrTagName, materials) => {
      watch(
        () => store.getters.getIIoTDataSources.vrTagsTable,
        (vrTags) => {
          const vrTag = vrTags.find(
            (tag) => tag.id === vrTagName && tag.direction === "output"
          );
          if (vrTag) {
            const isActive = vrTag.value;
            materials.forEach((item) => {
              if (item.material) {
                const targetAlpha = isActive ? 1 : 0;
                animateMaterialAlpha(item.material, targetAlpha);
              }
            });
          }
        },
        { deep: true }
      );
    };

    const monitorMaterialsMultipleTags = (vrTagNames, materials) => {
      watch(
        () => store.getters.getIIoTDataSources.vrTagsTable,
        (vrTags) => {
          const vrTagsFiltered = vrTags.filter(
            (tag) => vrTagNames.includes(tag.id) && tag.direction === "output"
          );
          if (vrTagsFiltered.length === vrTagNames.length) {
            const isActive = vrTagsFiltered.some((tag) => tag.value === true);
            materials.forEach((item) => {
              if (item.material) {
                const targetAlpha = isActive ? 1 : 0;
                animateMaterialAlpha(item.material, targetAlpha);
              }
            });
          }
        },
        { deep: true }
      );
    };

    const animateMaterialAlpha = (material, targetAlpha) => {
      const animation = new Animation(
        "alphaAnimation",
        "alpha",
        30,
        Animation.ANIMATIONTYPE_FLOAT,
        Animation.ANIMATIONLOOPMODE_CONSTANT
      );
      const keys = [];
      keys.push({ frame: 0, value: material.alpha });
      keys.push({ frame: 10, value: targetAlpha });
      animation.setKeys(keys);

      material.animations = [];
      material.animations.push(animation);

      scene.beginAnimation(material, 0, 10, false);
    };

    // ---------------------------------------------------------
    // 7) XR Support Check
    // ---------------------------------------------------------
    const checkXRSupport = async () => {
      if (navigator.xr) {
        try {
          const isSupported = await navigator.xr.isSessionSupported(
            "immersive-vr"
          );
          return isSupported;
        } catch (error) {
          return false;
        }
      }
      return false;
    };

    // ---------------------------------------------------------
    // 8) Lifecycle Hooks
    // ---------------------------------------------------------
    onMounted(() => {
      initBabylonScene().then(() => {
        // If you still want to monitor lamps/materials, call them here
        monitorLamps();
        monitorMaterials();

        // Play factory sound
        const sphereSound = new Sound(
          "sphereSound",
          "/assets/factory_sound_1.mp3",
          scene,
          () => {
            sphereSound.play();
          },
          { loop: true, autoplay: false, preload: true }
        );

        // Écouteur d'événement pour mettre à jour l'état du plein écran
        document.addEventListener("fullscreenchange", () => {
          isFullscreen.value = !!document.fullscreenElement;
        });
      });
    });

    onUnmounted(() => {
      if (buttonClickSound) buttonClickSound.dispose();
      if (factorySound) factorySound.dispose();
      if (scene) scene.dispose();
      if (engine) engine.dispose();

      // Supprimer l'écouteur d'événement pour mettre à jour l'état du plein écran
      document.removeEventListener("fullscreenchange", () => {
        isFullscreen.value = !!document.fullscreenElement;
      });
    });

    // Modifier la fonction toggleFullscreen
    const toggleFullscreen = async () => {
      if (!document.fullscreenElement) {
        const container = document.querySelector(".vr-container");
        try {
          await container.requestFullscreen();
          // Forcer une mise à jour après un court délai
          setTimeout(() => {
            if (engine) {
              engine.resize();
              if (scene && scene.activeCamera) {
                scene.activeCamera.viewport = new Viewport(0, 0, 1.0, 1.0);
              }
            }
          }, 100);
        } catch (err) {
          console.error("Erreur lors du passage en plein écran:", err);
        }
      } else {
        try {
          await document.exitFullscreen();
          // Forcer une mise à jour après un court délai
          setTimeout(() => {
            if (engine) {
              engine.resize();
              if (scene && scene.activeCamera) {
                scene.activeCamera.viewport = new Viewport(0, 0, 1.0, 1.0);
              }
            }
          }, 100);
        } catch (err) {
          console.error("Erreur lors de la sortie du plein écran:", err);
        }
      }
    };

    // Return for template
    return {
      renderCanvas,
      logo,
      loading,
      loadingPercentage,
      setupButtonInteraction,
      isFullscreen,
      toggleFullscreen,
    };
  },
};
</script>

<style scoped>
.vr-container {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  display: block;
  overflow: hidden;
}

.canvas-container {
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  padding: 0;
  display: block;
}

/* En mode plein écran */
.vr-container:fullscreen {
  width: 100vw;
  height: 100vh;
}

.vr-container:fullscreen .canvas-container {
  width: 100vw !important;
  height: 100vh !important;
}

/* Loading Screen */
.loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #000814, #0d1b2a);
  color: #ffd60a;
  text-align: center;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-text {
  margin-top: 20px;
  font-size: 24px;
  color: #ffd60a;
}

/* Circular progress bar */
.progress-circle {
  width: 150px;
  height: 150px;
  transform: rotate(-90deg);
}

.circle-bg {
  fill: none;
  stroke: #444;
  stroke-width: 2.8;
}

.circle {
  fill: none;
  stroke: #ffd60a;
  stroke-width: 2.8;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.3s;
}

/* Style du bouton plein écran */
.fullscreen-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.fullscreen-btn:hover {
  background: rgba(0, 0, 0, 0.7);
}
</style>

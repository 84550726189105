<template>
    <div class="vr-container">
      <!-- Loading Screen -->
      <div v-if="loading" class="loading-screen">
        <div class="loading-logo-container">
          <!-- Circular progress bar (optional) -->
          <svg class="progress-circle" viewBox="0 0 36 36">
            <path
              class="circle-bg"
              d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              class="circle"
              stroke-dasharray="100, 100"
              :stroke-dashoffset="100 - loadingPercentage"
              d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <!-- Rotated Logo -->
            <g transform="rotate(90 18 18)">
              <image :href="logo" x="8" y="8" height="20" width="20" />
            </g>
          </svg>
  
          <div class="loading-text">Loading... {{ loadingPercentage }}%</div>
        </div>
      </div>
  
      <!-- Babylon.js canvas -->
      <canvas ref="renderCanvas" class="canvas-container"></canvas>
    </div>
  </template>
  
  <script>
  import {
    Engine,
    Scene,
    ArcRotateCamera,
    DirectionalLight,
    HemisphericLight,
    Vector3,
    SceneLoader,
    Color3,
    StandardMaterial,
    ActionManager,
    Animation,
    ExecuteCodeAction,
    Sound,
    //Material,
    PhotoDome,
    Tools,
  } from "@babylonjs/core";
  import "@babylonjs/core/XR";
  import "@babylonjs/loaders/glTF";
  import logo from "@/assets/Logo_Medusa_Digital_Dark.webp";
  import { ref, watch, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  
  export default {
    name: "IMEPICALDERASLevel1",
    setup() {
      const store = useStore();
      const renderCanvas = ref(null);
  
      // Loader states
      const loading = ref(true);
      const loadingPercentage = ref(0);
  
      let engine = null;
      let scene = null;
  
      // --------------------------
      // Lamp & Material Mappings
      // --------------------------
      const lampMappings = [
        {
          lampNodeName: "Lamp_Color_1",
          vrTagName: "LampM1",
          materialName: "Lamp_Color_Choose_1",
          material: null,
        },
        {
          lampNodeName: "Lamp_Color_2",
          vrTagName: "LampM2",
          materialName: "Lamp_Color_Choose_2",
          material: null,
        },
        {
          lampNodeName: "Lamp_Color_3",
          vrTagName: "LampM3",
          materialName: "Lamp_Color_Choose_3",
          material: null,
        },
        {
          lampNodeName: "Lamp_Color_4",
          vrTagName: "LampM4",
          materialName: "Lamp_Color_Choose_4",
          material: null,
        },
        {
          lampNodeName: "Lamp_Color_5",
          vrTagName: "LampQ1",
          materialName: "Lamp_Color_Choose_5",
          material: null,
        },
        {
          lampNodeName: "Lamp_Color_6",
          vrTagName: "LampSensorB1",
          materialName: "Lamp_Color_Choose_6",
          material: null,
        },
        {
          lampNodeName: "Lamp_Color_7",
          vrTagName: "LampSensorB2",
          materialName: "Lamp_Color_Choose_7",
          material: null,
        },
        {
          lampNodeName: "Lamp_Color_8",
          vrTagName: "LampSensorB3",
          materialName: "Lamp_Color_Choose_8",
          material: null,
        },
      ];
  
      const materialMappings = [
        {
          vrTagName: "LampQ1",
          materials: [
            { meshName: "Text_Heating", materialName: "Legend_Heat", material: null },
            { meshName: "Water_T1_Heat", materialName: "Water_Mat_T1_Heat", material: null },
          ],
        },
        {
          vrTagName: "LampM3",
          materials: [
            {
              meshName: "Text_Transfer_T1_T2",
              materialName: "Legend_Transfer_T1_T2",
              material: null,
            },
          ],
        },
        {
          vrTagNames: ["LampM1", "LampM2"],
          materials: [
            {
              meshName: "Text_Transfer_T2_T1",
              materialName: "Legend_Transfer_T2_T1",
              material: null,
            },
          ],
        },
        {
          vrTagName: "LampSensorB1",
          materials: [
            { meshName: "Water_T1_High", materialName: "Water_Mat_T1_High", material: null },
          ],
        },
        {
          vrTagName: "LampSensorB2",
          materials: [
            { meshName: "Water_T1_Medium", materialName: "Water_Mat_T1_Medium", material: null },
          ],
        },
        {
          vrTagName: "LampSensorB3",
          materials: [
            { meshName: "Water_T1_Low", materialName: "Water_Mat_T1_Low", material: null },
          ],
        },
      ];
  
      let buttonClickSound = null;
      let factorySound = null;
  
      // ---------------------------------------------------------
      // 1) Files to Load (Chunk-based: each file = +1 increment)
      // ---------------------------------------------------------
      // Example load order (smaller to bigger, approximate):
      // 1) SpaceShip_platform.gltf
      // 2) SpaceShip_floor.gltf
      // 3) BoschUL-S1.gltf (with position/scale tweak, but no "postProcess")
      const filesToLoad = [
     { fileName: "SpaceShip_decals.gltf" },
     { fileName: "SpaceShip_platform.gltf" },
     { fileName: "SpaceShip_Vessel.gltf" },
     { fileName: "SpaceShip_floor.gltf" },
     { fileName: "SpaceShip_props.gltf" },
        {
          fileName: "BoschUL-S1.gltf",
          // We'll just store a boolean to do the transform in loadOneFile
          transformBosch: true,
        },
      ];
  
      // We'll track how many files have finished
      let filesCompleted = 0;
      const totalFiles = filesToLoad.length;
  
      // ---------------------------------------------------------
      // 2) Helper: Load One File, increment chunk progress
      // ---------------------------------------------------------
      function loadOneFile(asset) {
        return new Promise((resolve, reject) => {
          SceneLoader.ImportMesh(
            "",
            "/assets/",
            asset.fileName,
            scene,
            (meshes) => {
              // If we specifically want to reposition BoschUL-S1, do it here
              if (asset.transformBosch && meshes.length > 0) {
                const boschMesh = meshes[0];
                boschMesh.position.x += 5.8; // Move to the side
                boschMesh.position.y -= 3;   // Move down
                boschMesh.position.z -= 3.5; // Move forward
                boschMesh.scaling = new Vector3(-1.3, 1.3, 1.3); // Adjust scale
              }
  
              // Once the file is fully loaded, increment chunk-based progress
              filesCompleted++;
              loadingPercentage.value = Math.floor((filesCompleted / totalFiles) * 100);
  
              resolve(meshes);
            },
            null, // no partial progress callback
            (scene, error) => {
              reject(error);
            }
          );
        });
      }
  
      // ---------------------------------------------------------
      // 3) (Removed postLoadLC2030; no post-processing logic)
      // ---------------------------------------------------------
  
      // ---------------------------------------------------------
      // 4) Initialize Babylon Scene
      // ---------------------------------------------------------
      const initBabylonScene = async () => {
        const canvas = renderCanvas.value;
        engine = new Engine(canvas, true, { preserveDrawingBuffer: true });
        engine.useIdleDetection = false;
        scene = new Scene(engine);
        scene.clearColor = new Color3(0.95, 0.95, 0.95);
  
        const camera = new ArcRotateCamera(
          "camera",
          0,
          Math.PI / 2.5,
          5,
          new Vector3(10, 1, -3.85),
          scene
        );
        camera.attachControl(canvas, true);
  
        const light = new DirectionalLight("dirLight", new Vector3(0, -1, 1), scene);
        light.position = new Vector3(0, 5, -10);
        light.intensity = 0.6;
  
        const hemiLight = new HemisphericLight("hemiLight", new Vector3(0, 1, 0), scene);
        hemiLight.intensity = 0.3;
  
        // PhotoDome
        const skyDome = new PhotoDome(
          "spaceDome",
          "/assets/space_3.jpeg",
          { resolution: 256, size: 500 },
          scene
        );
        skyDome.mesh.rotation.y = Tools.ToRadians(45);
  
        // Environment
        const environment = scene.createDefaultEnvironment({
          enableGroundShadow: true,
          groundYBias: 2.8,
        });
        environment.setMainColor(new Color3(0.95, 0.95, 0.95));
        if (environment.skybox) {
          environment.skybox.material.alpha = 0;
        }
        if (environment.ground) {
          environment.ground.isVisible = false;
        }
  
        // Sounds
        buttonClickSound = new Sound(
          "buttonClick",
          "/assets/button_click.mp3",
          scene,
          null,
          { autoplay: false }
        );
        factorySound = new Sound(
          "factorySound",
          "/assets/space_sound.mp3",
          scene,
          null,
          { loop: true, autoplay: false }
        );
  
        // ------------------------------------------------
        // PARALLEL LOAD ALL FILES (with chunk-based progress)
        // ------------------------------------------------
        const promises = filesToLoad.map((asset) => loadOneFile(asset));
        // Wait for all
        await Promise.all(promises);
  
        // Hide loader
        loading.value = false;
  


        // XR
        let isXRSupported = await checkXRSupport();
        if (isXRSupported) {
          const xrHelper = await scene.createDefaultXRExperienceAsync({
            floorMeshes: [environment.ground],
          });
          xrHelper.teleportation.addFloorMesh(environment.ground);
          scene.xrHelper = xrHelper;
        }
  
        engine.runRenderLoop(() => {
          scene.render();
        });
  
        window.addEventListener("resize", () => {
          engine.resize();
        });
        engine.resize();
      };
  
      // ---------------------------------------------------------
      // 5) Setup button interactions
      // ---------------------------------------------------------
      const setupButtonInteraction = (buttonMesh, scene, vrTagName) => {
        const colorMesh =
          buttonMesh.getChildMeshes().find((m) => m.name === `${buttonMesh.name}_Color`) ||
          buttonMesh;
        const originalMaterial = colorMesh.material;
        const pressedMaterial = new StandardMaterial(`pressedMaterial_${vrTagName}`, scene);
        pressedMaterial.diffuseColor = new Color3(0.5, 1, 0.5);
  
        buttonMesh.actionManager = new ActionManager(scene);
        buttonMesh.actionManager.registerAction(
          new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
            // Click sound
            if (buttonClickSound) {
              buttonClickSound.play();
            }
            // Animate press
            colorMesh.material = pressedMaterial;
            const downPosition = buttonMesh.position.clone();
            downPosition.y -= 0.05;
  
            const pressDownAnimation = new Animation(
              "pressDownAnimation",
              "position",
              30,
              Animation.ANIMATIONTYPE_VECTOR3
            );
            pressDownAnimation.setKeys([
              { frame: 0, value: buttonMesh.position.clone() },
              { frame: 5, value: downPosition },
            ]);
  
            const releaseAnimation = new Animation(
              "releaseAnimation",
              "position",
              30,
              Animation.ANIMATIONTYPE_VECTOR3
            );
            releaseAnimation.setKeys([
              { frame: 0, value: downPosition },
              { frame: 5, value: buttonMesh.position.clone() },
            ]);
  
            scene.beginDirectAnimation(
              buttonMesh,
              [pressDownAnimation],
              0,
              5,
              false,
              1.0,
              () => {
                scene.beginDirectAnimation(
                  buttonMesh,
                  [releaseAnimation],
                  0,
                  5,
                  false,
                  1.0,
                  () => {
                    colorMesh.material = originalMaterial;
                  }
                );
              }
            );
  
            // VR tag input
            handleButtonPress(vrTagName);
          })
        );
      };
  
      const handleButtonPress = (vrTagName) => {
        const iiotDataSources = store.getters.getIIoTDataSources;
        const linkedVrTag = iiotDataSources.vrTagsTable.find(
          (tag) => tag.name === vrTagName
        );
        if (linkedVrTag && linkedVrTag.direction === "input") {
          store.dispatch("updateVrTagValue", { tagName: vrTagName, value: true });
          setTimeout(() => {
            store.dispatch("updateVrTagValue", { tagName: vrTagName, value: false });
          }, 100);
        }
      };
  
      // ---------------------------------------------------------
      // 6) Monitor Lamps & Materials
      // ---------------------------------------------------------
      const monitorLamps = () => {
        watch(
          () => store.getters.getIIoTDataSources.vrTagsTable,
          (vrTags) => {
            lampMappings.forEach((lamp) => {
              const lampTag = vrTags.find(
                (tag) => tag.id === lamp.vrTagName && tag.direction === "output"
              );
              if (lampTag && lamp.material) {
                updateLampMaterial(lamp.material, lampTag.value);
              }
            });
          },
          { deep: true }
        );
      };
  
      const updateLampMaterial = (material, isActive) => {
        if (material) {
          if (isActive) {
            material.diffuseColor = new Color3(1, 1, 0);
            material.emissiveColor = new Color3(1, 1, 0);
          } else {
            material.diffuseColor = new Color3(1, 1, 1);
            material.emissiveColor = new Color3(0, 0, 0);
          }
        }
      };
  
      const monitorMaterials = () => {
        materialMappings.forEach((mapping) => {
          if (mapping.vrTagName) {
            monitorMaterial(mapping.vrTagName, mapping.materials);
          } else if (mapping.vrTagNames) {
            monitorMaterialsMultipleTags(mapping.vrTagNames, mapping.materials);
          }
        });
      };
  
      const monitorMaterial = (vrTagName, materials) => {
        watch(
          () => store.getters.getIIoTDataSources.vrTagsTable,
          (vrTags) => {
            const vrTag = vrTags.find(
              (tag) => tag.id === vrTagName && tag.direction === "output"
            );
            if (vrTag) {
              const isActive = vrTag.value;
              materials.forEach((item) => {
                if (item.material) {
                  const targetAlpha = isActive ? 1 : 0;
                  animateMaterialAlpha(item.material, targetAlpha);
                }
              });
            }
          },
          { deep: true }
        );
      };
  
      const monitorMaterialsMultipleTags = (vrTagNames, materials) => {
        watch(
          () => store.getters.getIIoTDataSources.vrTagsTable,
          (vrTags) => {
            const vrTagsFiltered = vrTags.filter(
              (tag) => vrTagNames.includes(tag.id) && tag.direction === "output"
            );
            if (vrTagsFiltered.length === vrTagNames.length) {
              const isActive = vrTagsFiltered.some((tag) => tag.value === true);
              materials.forEach((item) => {
                if (item.material) {
                  const targetAlpha = isActive ? 1 : 0;
                  animateMaterialAlpha(item.material, targetAlpha);
                }
              });
            }
          },
          { deep: true }
        );
      };
  
      const animateMaterialAlpha = (material, targetAlpha) => {
        const animation = new Animation(
          "alphaAnimation",
          "alpha",
          30,
          Animation.ANIMATIONTYPE_FLOAT,
          Animation.ANIMATIONLOOPMODE_CONSTANT
        );
        const keys = [];
        keys.push({ frame: 0, value: material.alpha });
        keys.push({ frame: 10, value: targetAlpha });
        animation.setKeys(keys);
  
        material.animations = [];
        material.animations.push(animation);
  
        scene.beginAnimation(material, 0, 10, false);
      };
  
      // ---------------------------------------------------------
      // 7) XR Support Check
      // ---------------------------------------------------------
      const checkXRSupport = async () => {
        if (navigator.xr) {
          try {
            const isSupported = await navigator.xr.isSessionSupported("immersive-vr");
            return isSupported;
          } catch (error) {
            return false;
          }
        }
        return false;
      };
  
      // ---------------------------------------------------------
      // 8) Lifecycle Hooks
      // ---------------------------------------------------------
      onMounted(() => {
        initBabylonScene().then(() => {
          // If you still want to monitor lamps/materials, call them here
          monitorLamps();
          monitorMaterials();
  
          // Play factory sound
          const sphereSound = new Sound(
            "sphereSound",
            "/assets/space_sound.mp3",
            scene,
            () => {
              sphereSound.play();
            },
            { loop: true, autoplay: false, preload: true }
          );
        });
      });
  
      onUnmounted(() => {
        if (buttonClickSound) buttonClickSound.dispose();
        if (factorySound) factorySound.dispose();
        if (scene) scene.dispose();
        if (engine) engine.dispose();
      });
  
      // Return for template
      return {
        renderCanvas,
        logo,
        loading,
        loadingPercentage,
        setupButtonInteraction,
      };
    },
  };
  </script>
  
  <style scoped>
  .vr-container {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
  }
  
  .canvas-container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  /* Loading Screen */
  .loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #000814, #0d1b2a);
    color: #ffd60a;
    text-align: center;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .loading-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loading-text {
    margin-top: 20px;
    font-size: 24px;
    color: #ffd60a;
  }
  
  /* Circular progress bar */
  .progress-circle {
    width: 150px;
    height: 150px;
    transform: rotate(-90deg);
  }
  
  .circle-bg {
    fill: none;
    stroke: #444;
    stroke-width: 2.8;
  }
  
  .circle {
    fill: none;
    stroke: #ffd60a;
    stroke-width: 2.8;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.3s;
  }
  </style>
  
<template>
  <div class="vr-container">
    <!-- Loading Screen -->
    <div v-if="loading" class="loading-screen">
      <div class="loading-logo-container">
        <!-- Circular progress bar (optional) -->
        <svg class="progress-circle" viewBox="0 0 36 36">
          <path
            class="circle-bg"
            d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            class="circle"
            stroke-dasharray="100, 100"
            :stroke-dashoffset="100 - loadingPercentage"
            d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <!-- Rotated Logo -->
          <g transform="rotate(90 18 18)">
            <image :href="logo" x="8" y="8" height="20" width="20" />
          </g>
        </svg>

        <div class="loading-text">Loading... {{ loadingPercentage }}%</div>
      </div>
    </div>

    <!-- Babylon.js canvas -->
    <canvas ref="renderCanvas" class="canvas-container"></canvas>
  </div>
</template>

<script>
import {
  Engine,
  Scene,
  ArcRotateCamera,
  DirectionalLight,
  HemisphericLight,
  Vector3,
  SceneLoader,
  Color3,
  StandardMaterial,
  ActionManager,
  Animation,
  ExecuteCodeAction,
  Sound,
  Material,
  PhotoDome,
  Tools,
} from "@babylonjs/core";
import "@babylonjs/core/XR";
import "@babylonjs/loaders/glTF";
import logo from "@/assets/Logo_Medusa_Digital_Dark.webp";
import { ref, watch, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "SBG-Level3",
  setup() {
    const store = useStore();
    const renderCanvas = ref(null);

    // Loader states
    const loading = ref(true);
    const loadingPercentage = ref(0);

    let engine = null;
    let scene = null;

    // --------------------------
    // Lamp & Material Mappings
    // --------------------------
    const lampMappings = [
      { lampNodeName: "Lamp_Color_1", vrTagName: "LampM1", materialName: "Lamp_Color_Choose_1", material: null },
      { lampNodeName: "Lamp_Color_2", vrTagName: "LampM2", materialName: "Lamp_Color_Choose_2", material: null },
      { lampNodeName: "Lamp_Color_3", vrTagName: "LampM3", materialName: "Lamp_Color_Choose_3", material: null },
      { lampNodeName: "Lamp_Color_4", vrTagName: "LampM4", materialName: "Lamp_Color_Choose_4", material: null },
      { lampNodeName: "Lamp_Color_5", vrTagName: "LampQ1", materialName: "Lamp_Color_Choose_5", material: null },
      { lampNodeName: "Lamp_Color_6", vrTagName: "LampSensorB1", materialName: "Lamp_Color_Choose_6", material: null },
      { lampNodeName: "Lamp_Color_7", vrTagName: "LampSensorB2", materialName: "Lamp_Color_Choose_7", material: null },
      { lampNodeName: "Lamp_Color_8", vrTagName: "LampSensorB3", materialName: "Lamp_Color_Choose_8", material: null },
    ];

    const materialMappings = [
      {
        vrTagName: "LampQ1",
        materials: [
          { meshName: "Text_Heating", materialName: "Legend_Heat", material: null },
          { meshName: "Water_T1_Heat", materialName: "Water_Mat_T1_Heat", material: null },
        ],
      },
      {
        vrTagName: "LampM3",
        materials: [
          { meshName: "Text_Transfer_T1_T2", materialName: "Legend_Transfer_T1_T2", material: null },
        ],
      },
      {
        vrTagNames: ["LampM1", "LampM2"],
        materials: [
          { meshName: "Text_Transfer_T2_T1", materialName: "Legend_Transfer_T2_T1", material: null },
        ],
      },
      {
        vrTagName: "LampSensorB1",
        materials: [
          { meshName: "Water_T1_High", materialName: "Water_Mat_T1_High", material: null },
        ],
      },
      {
        vrTagName: "LampSensorB2",
        materials: [
          { meshName: "Water_T1_Medium", materialName: "Water_Mat_T1_Medium", material: null },
        ],
      },
      {
        vrTagName: "LampSensorB3",
        materials: [
          { meshName: "Water_T1_Low", materialName: "Water_Mat_T1_Low", material: null },
        ],
      },
    ];

    let buttonClickSound = null;
    let factorySound = null;

    // ---------------------------------------------------------
    // 1) Files to Load (Chunk-based: each file = +1 increment)
    // ---------------------------------------------------------
    // From smaller to bigger (approx order):
    // 1) SpaceShip_decals.gltf
    // 2) SpaceShip_platform.gltf
    // 3) SpaceShip_Vessel.gltf
    // 4) SpaceShip_floor.gltf
    // 5) SpaceShip_props.gltf
    // 6) LC2030_Alone.gltf (with special logic)
    const filesToLoad = [
      { fileName: "SpaceShip_decals.gltf" },
      { fileName: "SpaceShip_platform.gltf" },
      { fileName: "SpaceShip_Vessel.gltf" },
      { fileName: "SpaceShip_floor.gltf" },
      { fileName: "SpaceShip_props.gltf" },
      { fileName: "LC2030_Alone.gltf", postProcess: true }, // We'll run postLoadLC2030 after this one
    ];

    // We'll track how many files have finished
    let filesCompleted = 0;
    const totalFiles = filesToLoad.length; // 6

    // ---------------------------------------------------------
    // 2) Helper: Load One File, increment chunk progress
    // ---------------------------------------------------------
    function loadOneFile(asset) {
      return new Promise((resolve, reject) => {
        SceneLoader.ImportMesh(
          "",
          "/assets/",
          asset.fileName,
          scene,
          (meshes) => {
            if (meshes.length > 0) {
              meshes[0].position.y = 2.5;
            }

            // If it has postProcess = true, run postLoadLC2030
            if (asset.postProcess) {
              postLoadLC2030(meshes);
            }

            // Once the file is fully loaded, increment the chunk-based progress
            filesCompleted++;
            loadingPercentage.value = Math.floor((filesCompleted / totalFiles) * 100);

            resolve(meshes);
          },
          null, // no partial progress callback
          (scene, error) => {
            reject(error);
          }
        );
      });
    }

    // ---------------------------------------------------------
    // 3) postLoadLC2030 (buttons, lamps, etc.)
    // ---------------------------------------------------------
    function postLoadLC2030(loadedMeshes) {
      const meshMap = {};
      loadedMeshes.forEach((mesh) => {
        meshMap[mesh.name] = mesh;
      });

      // Setup buttons
      const HS4Button = meshMap["Button_Start_1"];
      if (HS4Button) setupButtonInteraction(HS4Button, scene, "Button HS4");

      const HS5Button = meshMap["Button_Stop_1"];
      if (HS5Button) setupButtonInteraction(HS5Button, scene, "Button HS5");

      const HS1Button = meshMap["Button_Start_2"];
      if (HS1Button) setupButtonInteraction(HS1Button, scene, "Button HS1");

      const HS2Button = meshMap["Button_Start_3"];
      if (HS2Button) setupButtonInteraction(HS2Button, scene, "Button HS2");

      const HS3Button = meshMap["Button_Start_4"];
      if (HS3Button) setupButtonInteraction(HS3Button, scene, "Button HS3");

      // Assign materials to lampMappings
      lampMappings.forEach((lamp) => {
        const material = scene.getMaterialByName(lamp.materialName);
        if (material) {
          lamp.material = material;
        } else {
          console.warn(
            `Material '${lamp.materialName}' not found for lamp '${lamp.lampNodeName}'`
          );
        }
      });

      // Assign materials to materialMappings
      materialMappings.forEach((mapping) => {
        mapping.materials.forEach((item) => {
          const mesh = scene.getMeshByName(item.meshName);
          if (mesh) {
            const mat = scene.getMaterialByName(item.materialName);
            if (mat) {
              item.material = mat;
              mesh.material = mat;
              mat.alpha = 0;
              mat.transparencyMode = Material.MATERIAL_ALPHABLEND;
              mat.needDepthPrePass = true;
              mat.alphaMode = Engine.ALPHA_COMBINE;
            } else {
              console.warn(
                `Material '${item.materialName}' not found for mesh '${item.meshName}'`
              );
            }
          } else {
            console.warn(`Mesh '${item.meshName}' not found`);
          }
        });
      });

      // Adjust Water_T2
      const waterT2Mesh = scene.getMeshByName("Water_T2");
      if (waterT2Mesh) {
        const waterT2Material = waterT2Mesh.material;
        if (waterT2Material) {
          waterT2Material.alpha = 0.1;
          waterT2Material.transparencyMode = Material.MATERIAL_ALPHABLEND;
          waterT2Material.needDepthPrePass = true;
          waterT2Material.alphaMode = Engine.ALPHA_COMBINE;
        } else {
          console.warn("Material 'Water_Mat_T2' not found for 'Water_T2'");
        }
      } else {
        console.warn("Mesh 'Water_T2' not found");
      }
    }

    // ---------------------------------------------------------
    // 4) Initialize Babylon Scene
    // ---------------------------------------------------------
    const initBabylonScene = async () => {
      const canvas = renderCanvas.value;
      engine = new Engine(canvas, true, { preserveDrawingBuffer: true });
      engine.useIdleDetection = false;
      scene = new Scene(engine);
      scene.clearColor = new Color3(0.95, 0.95, 0.95);

      const camera = new ArcRotateCamera(
        "camera",
        0,
        Math.PI / 2.5,
        5,
        new Vector3(5, 1, -3.85),
        scene
      );
      camera.attachControl(canvas, true);

      const light = new DirectionalLight("dirLight", new Vector3(0, -1, 1), scene);
      light.position = new Vector3(0, 5, -10);
      light.intensity = 0.6;

      const hemiLight = new HemisphericLight("hemiLight", new Vector3(0, 1, 0), scene);
      hemiLight.intensity = 0.3;

      // PhotoDome
      const skyDome = new PhotoDome(
        "spaceDome",
        "/assets/space_3.jpeg",
        { resolution: 256, size: 500 },
        scene
      );
      skyDome.mesh.rotation.y = Tools.ToRadians(45);

      // Environment
      const environment = scene.createDefaultEnvironment({
        enableGroundShadow: true,
        groundYBias: 2.8,
      });
      environment.setMainColor(new Color3(0.95, 0.95, 0.95));
      if (environment.skybox) {
        environment.skybox.material.alpha = 0;
      }
      if (environment.ground) {
        environment.ground.isVisible = false;
      }

      // Sounds
      buttonClickSound = new Sound(
        "buttonClick",
        "/assets/button_click.mp3",
        scene,
        null,
        { autoplay: false }
      );
      factorySound = new Sound(
        "factorySound",
        "/assets/factory_sound.mp3",
        scene,
        null,
        { loop: true, autoplay: false }
      );

      // ------------------------------------------------
      // PARALLEL LOAD ALL FILES (with chunk-based progress)
      // ------------------------------------------------
      const promises = filesToLoad.map((asset) => loadOneFile(asset));
      // Wait for all
      await Promise.all(promises);

      // Hide loader
      loading.value = false;

      // XR
      let isXRSupported = await checkXRSupport();
      if (isXRSupported) {
        const xrHelper = await scene.createDefaultXRExperienceAsync({
          floorMeshes: [environment.ground],
        });
        xrHelper.teleportation.addFloorMesh(environment.ground);
        scene.xrHelper = xrHelper;
      }

      engine.runRenderLoop(() => {
        scene.render();
      });

      window.addEventListener("resize", () => {
        engine.resize();
      });
      engine.resize();
    };

    // ---------------------------------------------------------
    // 5) Setup button interactions
    // ---------------------------------------------------------
    const setupButtonInteraction = (buttonMesh, scene, vrTagName) => {
      const colorMesh =
        buttonMesh.getChildMeshes().find((m) => m.name === `${buttonMesh.name}_Color`) ||
        buttonMesh;
      const originalMaterial = colorMesh.material;
      const pressedMaterial = new StandardMaterial(`pressedMaterial_${vrTagName}`, scene);
      pressedMaterial.diffuseColor = new Color3(0.5, 1, 0.5);

      buttonMesh.actionManager = new ActionManager(scene);
      buttonMesh.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
          // Click sound
          if (buttonClickSound) {
            buttonClickSound.play();
          }
          // Animate press
          colorMesh.material = pressedMaterial;
          const downPosition = buttonMesh.position.clone();
          downPosition.y -= 0.05;

          const pressDownAnimation = new Animation(
            "pressDownAnimation",
            "position",
            30,
            Animation.ANIMATIONTYPE_VECTOR3
          );
          pressDownAnimation.setKeys([
            { frame: 0, value: buttonMesh.position.clone() },
            { frame: 5, value: downPosition },
          ]);

          const releaseAnimation = new Animation(
            "releaseAnimation",
            "position",
            30,
            Animation.ANIMATIONTYPE_VECTOR3
          );
          releaseAnimation.setKeys([
            { frame: 0, value: downPosition },
            { frame: 5, value: buttonMesh.position.clone() },
          ]);

          scene.beginDirectAnimation(
            buttonMesh,
            [pressDownAnimation],
            0,
            5,
            false,
            1.0,
            () => {
              scene.beginDirectAnimation(
                buttonMesh,
                [releaseAnimation],
                0,
                5,
                false,
                1.0,
                () => {
                  colorMesh.material = originalMaterial;
                }
              );
            }
          );

          // VR tag input
          handleButtonPress(vrTagName);
        })
      );
    };

    const handleButtonPress = (vrTagName) => {
      const iiotDataSources = store.getters.getIIoTDataSources;
      const linkedVrTag = iiotDataSources.vrTagsTable.find(
        (tag) => tag.name === vrTagName
      );
      if (linkedVrTag && linkedVrTag.direction === "input") {
        store.dispatch("updateVrTagValue", { tagName: vrTagName, value: true });
        setTimeout(() => {
          store.dispatch("updateVrTagValue", { tagName: vrTagName, value: false });
        }, 100);
      }
    };

    // ---------------------------------------------------------
    // 6) Monitor Lamps & Materials
    // ---------------------------------------------------------
    const monitorLamps = () => {
      watch(
        () => store.getters.getIIoTDataSources.vrTagsTable,
        (vrTags) => {
          lampMappings.forEach((lamp) => {
            const lampTag = vrTags.find(
              (tag) => tag.id === lamp.vrTagName && tag.direction === "output"
            );
            if (lampTag && lamp.material) {
              updateLampMaterial(lamp.material, lampTag.value);
            }
          });
        },
        { deep: true }
      );
    };

    const updateLampMaterial = (material, isActive) => {
      if (material) {
        if (isActive) {
          material.diffuseColor = new Color3(1, 1, 0);
          material.emissiveColor = new Color3(1, 1, 0);
        } else {
          material.diffuseColor = new Color3(1, 1, 1);
          material.emissiveColor = new Color3(0, 0, 0);
        }
      }
    };

    const monitorMaterials = () => {
      materialMappings.forEach((mapping) => {
        if (mapping.vrTagName) {
          monitorMaterial(mapping.vrTagName, mapping.materials);
        } else if (mapping.vrTagNames) {
          monitorMaterialsMultipleTags(mapping.vrTagNames, mapping.materials);
        }
      });
    };

    const monitorMaterial = (vrTagName, materials) => {
      watch(
        () => store.getters.getIIoTDataSources.vrTagsTable,
        (vrTags) => {
          const vrTag = vrTags.find(
            (tag) => tag.id === vrTagName && tag.direction === "output"
          );
          if (vrTag) {
            const isActive = vrTag.value;
            materials.forEach((item) => {
              if (item.material) {
                const targetAlpha = isActive ? 1 : 0;
                animateMaterialAlpha(item.material, targetAlpha);
              }
            });
          }
        },
        { deep: true }
      );
    };

    const monitorMaterialsMultipleTags = (vrTagNames, materials) => {
      watch(
        () => store.getters.getIIoTDataSources.vrTagsTable,
        (vrTags) => {
          const vrTagsFiltered = vrTags.filter(
            (tag) => vrTagNames.includes(tag.id) && tag.direction === "output"
          );
          if (vrTagsFiltered.length === vrTagNames.length) {
            const isActive = vrTagsFiltered.some((tag) => tag.value === true);
            materials.forEach((item) => {
              if (item.material) {
                const targetAlpha = isActive ? 1 : 0;
                animateMaterialAlpha(item.material, targetAlpha);
              }
            });
          }
        },
        { deep: true }
      );
    };

    const animateMaterialAlpha = (material, targetAlpha) => {
      const animation = new Animation(
        "alphaAnimation",
        "alpha",
        30,
        Animation.ANIMATIONTYPE_FLOAT,
        Animation.ANIMATIONLOOPMODE_CONSTANT
      );
      const keys = [];
      keys.push({ frame: 0, value: material.alpha });
      keys.push({ frame: 10, value: targetAlpha });
      animation.setKeys(keys);

      material.animations = [];
      material.animations.push(animation);

      scene.beginAnimation(material, 0, 10, false);
    };

    // ---------------------------------------------------------
    // 7) XR Support Check
    // ---------------------------------------------------------
    const checkXRSupport = async () => {
      if (navigator.xr) {
        try {
          const isSupported = await navigator.xr.isSessionSupported("immersive-vr");
          return isSupported;
        } catch (error) {
          return false;
        }
      }
      return false;
    };

    // ---------------------------------------------------------
    // 8) Lifecycle Hooks
    // ---------------------------------------------------------
    onMounted(() => {
      initBabylonScene().then(() => {
        monitorLamps();
        monitorMaterials();

        // Play factory sound
        const sphereSound = new Sound(
          "sphereSound",
          "/assets/factory_sound_1.mp3",
          scene,
          () => {
            sphereSound.play();
          },
          { loop: true, autoplay: false, preload: true }
        );
      });
    });

    onUnmounted(() => {
      if (buttonClickSound) buttonClickSound.dispose();
      if (factorySound) factorySound.dispose();
      if (scene) scene.dispose();
      if (engine) engine.dispose();
    });

    // Return for template
    return {
      renderCanvas,
      logo,
      loading,
      loadingPercentage,
    };
  },
};
</script>

<style scoped>
.vr-container {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  display: block;
}

.canvas-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Loading Screen */
.loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #000814, #0d1b2a);
  color: #ffd60a;
  text-align: center;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-text {
  margin-top: 20px;
  font-size: 24px;
  color: #ffd60a;
}

/* Circular progress bar */
.progress-circle {
  width: 150px;
  height: 150px;
  transform: rotate(-90deg);
}

.circle-bg {
  fill: none;
  stroke: #444;
  stroke-width: 2.8;
}

.circle {
  fill: none;
  stroke: #ffd60a;
  stroke-width: 2.8;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.3s;
}
</style>
